export default {
  name: 'Spinner',

  props: {
    altColor: {
      default: false,
      type: Boolean,
    },
    size: {
      default: 50,
      type: Number,
    },
  },

  computed: {
    style() {
      const { size } = this;

      return {
        height: `${size}px`,
        width: `${size}px`,
      };
    },
  },
};
